<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS POSIBLES CLIENTES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="fas fa-business-time fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaClientes.length}}</span>
                            <br>
                            <span class="text-muted">Posibles clientes registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de los posibles clientes </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarPosibleCliente = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo posible cliente
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Posibles clientes registrados</span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaClientes" :fields="camposClientes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

                                <template v-slot:cell(razonSocial)="data">
                                    <a :href="`${base_host}${data.item.path}/login`" target="_blank" v-if="data.item.path != null" :id="`popover-target-${data.item.idCliente}`"> {{data.value | truncate(30)}}</a>
                                    <span v-if="data.item.path == null" :id="`popover-target-${data.item.idCliente}`"> {{data.value | truncate(30)}}</span>
                                      <b-popover :target="`popover-target-${data.item.idCliente}`" triggers="hover" placement="righttop">
                                        <template #title>{{data.item.nombreComercial ?  data.item.nombreComercial : 'Sin nombre comercial'| truncate(30)}}</template>
                                        <b-row>
                                            <b-col md="12" class="text-center">
                                                <img :style="`background:${data.item.primaryColor};`" width="150px" :src="data.item.urlLogo" class=" img-responsive">
                                            </b-col>
                                            <b-col md="12" class="text-center">
                                                <span class="h6 mt-2">{{data.item.numeroRuc}}</span>
                                            </b-col>
                                        </b-row>
                                    </b-popover>
                                </template>
                                <template v-slot:cell(representanteLegal)="data">
                                    <span>{{data.item.representanteLegal | truncate(30)}}</span>
                                </template>
                                <template v-slot:cell(opciones)="param">

                                    <b-button size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" @click="verDetalleCliente(param)">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Activar'" @click="cambiarEstadoCliente(param)" variant="success">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-check  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCliente(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" class="my-0 float-right" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarPosibleCliente" :closeOnBackdrop="false" size="lg" color="dark">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo posible cliente </span>
            </h6>
            <CButtonClose @click="modalRegistrarPosibleCliente = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPosibleCliente)">
                <b-row>
                    <b-col lg="4">
                        <validation-provider name="número de ruc" :rules="{ required: true,numeric:true,min:11,max:11 }" v-slot="validationContext">
                            <b-form-group label="Número de ruc:" class="mb-2">
                                <b-overlay :show="cargaApi" opacity="0.5" spinner-small>
                                    <b-input-group>
                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese número de RUC" v-model="datosPosibleCliente.numeroRuc"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="disabledBtnApi" v-c-tooltip.hover.click="'Buscar'" variant="dark" @click="apiSunat()">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-overlay>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Razón social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosPosibleCliente.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="nombre comercial" v-slot="validationContext">
                            <b-form-group label="Nombre comercial:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre comercial" v-model="datosPosibleCliente.nombreComercial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="departamento" rules="required" v-slot="{errors}">
                            <b-form-group label="Departamento:" class="mb-2">
                                <v-select :reduce="listaDepartamentos => listaDepartamentos.idDepartamento" label="nombre" @input="obtenerProvincia()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosPosibleCliente.idDepartamento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosPosibleCliente.idDepartamento" :options="listaDepartamentos" @search:blur="blurDepartamento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="provincia" rules="required" v-slot="{errors}">
                            <b-form-group label="Provincia:" class="mb-2">
                                <v-select :reduce="listaProvincias => listaProvincias.idProvincia" label="nombre" @input="obtenerDistrito()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosPosibleCliente.idProvincia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosPosibleCliente.idProvincia" :options="listaProvincias" @search:blur="blurProvincia">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="distrito" rules="required" v-slot="{errors}">
                            <b-form-group label="Distrito:" class="mb-2">
                                <v-select :reduce="listaDistritos => listaDistritos.idDistrito" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosPosibleCliente.idDistrito , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosPosibleCliente.idDistrito" :options="listaDistritos" @search:blur="blurDistrito">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="localidad"  v-slot="validationContext">
                            <b-form-group label="Localidad:" class="mb-2">
                                <b-form-input autocomplete="off" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosPosibleCliente.localidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="3">
                        <validation-provider name="código postal" v-slot="validationContext">
                            <b-form-group label="Código Postal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código postal" v-model="datosPosibleCliente.codigoPostal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="dirección fiscal" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Dirección fiscal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección fiscal" v-model="datosPosibleCliente.direccionFiscal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="contacto"  v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosPosibleCliente.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="teléfono del contacto" :rules="{ numeric: true }" v-slot="validationContext">
                            <b-form-group label="Teléfono del contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono del contacto" v-model="datosPosibleCliente.telefonoContacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="correo del contacto" :rules="{  email: true }" v-slot="validationContext">
                            <b-form-group label="Correo del contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo del contacto" v-model="datosPosibleCliente.correoContacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="actividad económica" v-slot="validationContext">
                            <b-form-group label="Actividad económica:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el actividad económica" v-model="datosPosibleCliente.actividadEconomica"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="teléfono" :rules="{ required: true, numeric: true }" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosPosibleCliente.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="correo" :rules="{ required: true, email: true }" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosPosibleCliente.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="web" v-slot="validationContext">
                            <b-form-group label="Web:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la web" v-model="datosPosibleCliente.web"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="representante legal" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Representante legal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosPosibleCliente.representanteLegal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="cargo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Cargo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo" v-model="datosPosibleCliente.cargo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese las observaciones" v-model="datosPosibleCliente.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarPosibleCliente = false">
                            Cancelar
                        </b-button>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarPosibleCliente" :closeOnBackdrop="false" size="lg" color="dark">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar posible cliente </span>
            </h6>
            <CButtonClose @click="modalActualizarPosibleCliente = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarPosibleCliente)">
                <b-row>
                    <b-col lg="4">
                        <validation-provider name="número de ruc" :rules="{ required: true,numeric:true,min:11,max:11 }" v-slot="validationContext">
                            <b-form-group label="Número de ruc:" class="mb-2">
                                <b-overlay :show="cargaApi" opacity="0.5" spinner-small>
                                    <b-input-group>
                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese número de RUC" v-model="datosActualizarPosibleCliente.numeroRuc"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="disabledBtnApi" v-c-tooltip.hover.click="'Buscar'" size="md" variant="dark" @click="apiSunat()">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-overlay>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Razón social:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosActualizarPosibleCliente.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="nombre comercial" v-slot="validationContext">
                            <b-form-group label="Nombre comercial:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre comercial" v-model="datosActualizarPosibleCliente.nombreComercial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="departamento" rules="required" v-slot="{errors}">
                            <b-form-group label="Departamento:" class="mb-2">
                                <v-select :reduce="listaDepartamentos => listaDepartamentos.idDepartamento" label="nombre" @input="obtenerProvincia()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarPosibleCliente.idDepartamento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarPosibleCliente.idDepartamento" :options="listaDepartamentos" @search:blur="blurDepartamentoActualizar">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="provincia" rules="required" v-slot="{errors}">
                            <b-form-group label="Provincia:" class="mb-2">
                                <v-select :reduce="listaProvincias => listaProvincias.idProvincia" label="nombre" @input="obtenerDistrito()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarPosibleCliente.idProvincia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarPosibleCliente.idProvincia" :options="listaProvincias" @search:blur="blurProvinciaActualizar">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="distrito" rules="required" v-slot="{errors}">
                            <b-form-group label="Distrito:" class="mb-2">
                                <v-select :reduce="listaDistritos => listaDistritos.idDistrito" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarPosibleCliente.idDistrito , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarPosibleCliente.idDistrito" :options="listaDistritos" @search:blur="blurDistritoActualizar">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="localidad" v-slot="validationContext">
                            <b-form-group label="Localidad:" class="mb-2">
                                <b-form-input autocomplete="off" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosActualizarPosibleCliente.localidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="3">
                        <validation-provider name="código postal" v-slot="validationContext">
                            <b-form-group label="Código Postal:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el código postal" v-model="datosActualizarPosibleCliente.codigoPostal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="dirección fiscal" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Dirección fiscal:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la dirección fiscal" v-model="datosActualizarPosibleCliente.direccionFiscal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="contacto" v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosActualizarPosibleCliente.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="teléfono del contacto" :rules="{ numeric: true }" v-slot="validationContext">
                            <b-form-group label="Teléfono del contacto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono del contacto" v-model="datosActualizarPosibleCliente.telefonoContacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="correo del contacto" :rules="{ email: true }" v-slot="validationContext">
                            <b-form-group label="Correo del contacto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el correo del contacto" v-model="datosActualizarPosibleCliente.correoContacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="actividad económica" v-slot="validationContext">
                            <b-form-group label="Actividad económica:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el actividad económica" v-model="datosActualizarPosibleCliente.actividadEconomica"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="teléfono" :rules="{ numeric: true }" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarPosibleCliente.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="correo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosActualizarPosibleCliente.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="web" v-slot="validationContext">
                            <b-form-group label="Web:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la web" v-model="datosActualizarPosibleCliente.web"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="representante legal" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Representante legal:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosActualizarPosibleCliente.representanteLegal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="cargo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Cargo:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo" v-model="datosActualizarPosibleCliente.cargo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="observaciones" :rules="{  }" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" size="md" placeholder="Ingrese las observaciones" v-model="datosActualizarPosibleCliente.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarPosibleCliente = false">
                            Cancelar
                        </b-button>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>


import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            base_host: CONSTANTES.URL_RUTA_FRONTEND,
            listaDistritos: [],
            listaProvincias: [],
            listaDepartamentos: [],

            listaClientes: [],

            cargaApi: false,
            disabledBtnApi: true,

            estadisticos: {
                estadoRegistrado: "",
                totalRegistros: "",
                estadoActivo: "",
                estadoSuspendido: "",
                estadoBaja: "",
            },

            camposClientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Razón Social",
                    class: "text-center"
                },
                // {
                //     key: "nombreComercial",
                //     label: "Nombre Comercial",
                //     class: "text-center"
                // },
                // {
                //     key: "numeroRuc",
                //     label: "RUC",
                //     class: "text-center"
                // },
                {
                    key: "representanteLegal",
                    label: "Representante",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "correo",
                    label: "Correo",
                    class: "text-center",
                },

                {
                    label: "",
                    key: "opciones",
                    class: "text-center",
                },
            ],

            modalRegistrarPosibleCliente: false,
            modalActualizarPosibleCliente: false,

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            datosActualizarPosibleCliente: {
                idCliente: null,
                numeroRuc: '',
                razonSocial: '',
                nombreComercial: '',
                idDepartamento: null,
                idProvincia: null,
                idDistrito: null,
                localidad: '',
                codigoPostal: '',
                direccionFiscal: '',
                contacto: '',
                telefonoContacto: '',
                correoContacto: '',
                actividadEconomica: '',
                telefono: '',
                correo: '',
                web: '',
                representanteLegal: '',
                cargo: '',
                observaciones: '',
                estado: 3
            },

            datosPosibleCliente: {
                numeroRuc: '',
                razonSocial: '',
                nombreComercial: '',
                idDepartamento: null,
                idProvincia: null,
                idDistrito: null,
                localidad: '',
                codigoPostal: '',
                direccionFiscal: '',
                contacto: '',
                telefonoContacto: '',
                correoContacto: '',
                actividadEconomica: '',
                telefono: '',
                correo: '',
                web: '',
                representanteLegal: '',
                cargo: '',
                observaciones: '',
                estado: 3
            },
            disabled: false
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },

        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-clientes", {
                    params: {
                        estado: 3
                    }
                })
                .then(function (response) {
                    me.listaClientes = response.data;
                    me.filasTotales = me.listaClientes.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        verDetalleCliente(param) {
            let me = this;

            me.datosActualizarPosibleCliente.idCliente = param.item.idCliente;
            me.datosActualizarPosibleCliente.numeroRuc = param.item.numeroRuc;
            me.datosActualizarPosibleCliente.razonSocial = param.item.razonSocial;
            me.datosActualizarPosibleCliente.nombreComercial = param.item.nombreComercial;
            me.obtenerDepartamento();
            me.datosActualizarPosibleCliente.idDepartamento = param.item.idDepartamento;
            me.obtenerProvincia();
            me.datosActualizarPosibleCliente.idProvincia = param.item.idProvincia;
            me.obtenerDistrito();
            me.datosActualizarPosibleCliente.idDistrito = param.item.idDistrito;
            me.datosActualizarPosibleCliente.localidad = param.item.localidad;
            me.datosActualizarPosibleCliente.codigoPostal = param.item.codigoPostal;
            me.datosActualizarPosibleCliente.direccionFiscal = param.item.direccionFiscal;
            me.datosActualizarPosibleCliente.contacto = param.item.contacto;
            me.datosActualizarPosibleCliente.telefonoContacto = param.item.telefonoContacto;
            me.datosActualizarPosibleCliente.correoContacto = param.item.correoContacto;
            me.datosActualizarPosibleCliente.actividadEconomica = param.item.actividadEconomica;
            me.datosActualizarPosibleCliente.telefono = param.item.telefono;
            me.datosActualizarPosibleCliente.correo = param.item.correo;
            me.datosActualizarPosibleCliente.web = param.item.web;
            me.datosActualizarPosibleCliente.nombreLogo = param.item.nombreLogo;
            me.datosActualizarPosibleCliente.representanteLegal = param.item.representanteLegal;
            me.datosActualizarPosibleCliente.cargo = param.item.cargo;
            me.datosActualizarPosibleCliente.observaciones = param.item.observaciones;
            me.datosActualizarPosibleCliente.estado = param.item.estado;

            me.modalActualizarPosibleCliente = true;
        },
        blurDepartamento() {
            this.computedForm.refs.departamento.validate();
        },
        blurProvincia() {
            this.computedForm.refs.provincia.validate();
        },
        blurDistrito() {
            this.computedForm.refs.distrito.validate();
        },
        blurDepartamentoActualizar() {
            this.computedFormActualizar.refs.departamento.validate();
        },
        blurProvinciaActualizar() {
            this.computedFormActualizar.refs.provincia.validate();
        },
        blurDistritoActualizar() {
            this.computedFormActualizar.refs.distrito.validate();
        },
        apiSunat() {
            let me = this;
            let mi_token = "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.cargaApi = true;
            axios
                .get("https://apiperu.dev/api/ruc/" + me.datosPosibleCliente.numeroRuc, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosPosibleCliente.razonSocial = response.data.data.nombre_o_razon_social;
                        me.datosPosibleCliente.direccionFiscal = response.data.data.direccion;
                        me.datosPosibleCliente.idDepartamento = response.data.data.ubigeo[0]
                        me.obtenerProvincia();
                        me.datosPosibleCliente.idProvincia = response.data.data.ubigeo[1]
                        me.obtenerDistrito();
                        me.datosPosibleCliente.idDistrito = response.data.data.ubigeo[2]
                    } else {
                        me.swat('error', 'No se encontró el número de ruc!')
                    }
                    me.cargaApi = false;
                })
                .catch((error) => {
                    me.cargaApi = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarCliente(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar al cliente?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-cliente", {
                            idCliente: param.item.idCliente
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarClientes();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        cambiarEstadoCliente(param) {
            let me = this;
            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/cambiar-estado-cliente", {
                    idCliente: param.item.idCliente,
                    estado: 2
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`,
                    }
                })
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarClientes();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        obtenerDepartamento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-departamento")
                .then(function (response) {
                    me.listaDepartamentos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        obtenerProvincia() {
            let me = this;
            (me.datosPosibleCliente.idProvincia = null),
            (me.datosPosibleCliente.idDistrito = null),
            (me.datosActualizarPosibleCliente.idProvincia = null),
            (me.datosActualizarPosibleCliente.idDistrito = null),
            (me.listaDistritos = []),

            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-provincia", {
                    params: {
                        idDepartamento: me.datosPosibleCliente.idDepartamento != null ? me.datosPosibleCliente.idDepartamento : me.datosActualizarPosibleCliente.idDepartamento,
                    },
                })
                .then(function (response) {
                    me.listaProvincias = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        obtenerDistrito() {
            let me = this;
            (me.datosPosibleCliente.idDistrito = null),
            (me.datosActualizarPosibleCliente.idDistrito = null),
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-distrito", {
                    params: {
                        idProvincia: me.datosPosibleCliente.idProvincia != null ? me.datosPosibleCliente.idProvincia : me.datosActualizarPosibleCliente.idProvincia,
                    },
                })
                .then(function (response) {
                    me.listaDistritos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarPosibleCliente() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-cliente",
                    me.datosPosibleCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarPosibleCliente = false;
                    me.listarClientes();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarPosibleCliente() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-cliente",
                    me.datosActualizarPosibleCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarPosibleCliente = false;
                    me.listarClientes();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarPosibleCliente() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosPosibleCliente.numeroRuc = '',
                this.datosPosibleCliente.razonSocial = '',
                this.datosPosibleCliente.nombreComercial = '',
                this.datosPosibleCliente.idDepartamento = null,
                this.datosPosibleCliente.idProvincia = null,
                this.datosPosibleCliente.idDistrito = null,
                this.datosPosibleCliente.localidad = '',
                this.datosPosibleCliente.codigoPostal = '',
                this.datosPosibleCliente.direccionFiscal = '',
                this.datosPosibleCliente.contacto = '',
                this.datosPosibleCliente.telefonoContacto = '',
                this.datosPosibleCliente.correoContacto = '',
                this.datosPosibleCliente.actividadEconomica = '',
                this.datosPosibleCliente.telefono = '',
                this.datosPosibleCliente.correo = '',
                this.datosPosibleCliente.web = '',
                this.datosPosibleCliente.representanteLegal = '',
                this.datosPosibleCliente.cargo = '',
                this.datosPosibleCliente.observaciones = ''
        },
        resetModalActualizarPosibleCliente() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalRegistrarPosibleCliente: function (val) {
            if (val == false) {
                this.resetModalRegistrarPosibleCliente();
            }
        },
        modalActualizarPosibleCliente: function (val) {
            if (val == false) {
                this.resetModalActualizarPosibleCliente();
            }
        },
        'datosPosibleCliente.numeroRuc': function (val) {
            if (val.length == 11) {
                this.disabledBtnApi = false
            } else {
                this.disabledBtnApi = true
            }
        },
    },
    mounted() {
        this.listarClientes();
        this.obtenerDepartamento();
    }
}
</script>
